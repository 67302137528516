import request from "./request";
/**
 * @description: 登录接口
 * @param {*} data  整个form
 * @return {*}
 */
export function login(data) {
  return request({
    url: "auth/login",
    method: "post",
    data,
  });
}

/**
 * @description: 左侧菜单栏
 * @param {*} method  请求方法
 * @param {*} data
 * @return {*}
 */
export function Menu() {
  return request({
    url: "role/menuTree",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
// 首页接口
export function getIndexList() {
  return request({
    url: "index/data",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

/**
 * @description: 客户协议接口
 * @param {*} data
 * @param {*} method
 * @return {*}
 */
export function getList(data) {
  return request({
    url: "user/list",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: data,
  });
}
// 归属合伙人接口
export function getpidList(data) {
  return request({
    url: "partner/list",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: data,
  });
}

/**
 * @description: 添加协议客户数据
 * @param {*} data
 * @param {*} method
 * @return {*}
 */
export function add(data) {
  return request({
    url: "user/saveUser",
    method: "post",
    data,
  });
}
/**
 * @description: 用户修改
 * @param {*} data
 * @return {*}
 */
export function edit(data) {
  // console.log(data,'data');
  return request({
    url: `user/saveUser`,
    method: "post",
    data,
  });
}

export function address(data) {
  return request({
    url: "user/addressList",
    method: "get",
    params: data,
  });
}
export function userBackList(data) {
  return request({
    url: "userBack/list",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: data,
  });
}
export function roleList() {
  return request({
    url: "role/list",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
export function userBa(data) {
  return request({
    url: `userBack/save`,
    method: "post",
    data,
  });
}
export function adduserBa(data) {
  return request({
    url: `userBack/save`,
    method: "post",
    data,
  });
}
export function edituserBa(data) {
  return request({
    url: `userBack/save`,
    method: "post",
    data,
  });
}
// 禁用
export function forbiddenStatus(data) {
  return request({
    url: `user/updateStatus`,
    method: "post",
    data,
  });
}
// 删除
export function remove(data) {
  return request({
    url: `user/updateStatus`,
    method: "post",
    data,
  });
}
// 新增地址
export function addAddress(data) {
  return request({
    url: `user/saveAddress`,
    method: "post",
    data,
  });
}
// 编辑地址
export function editAddress(data) {
  return request({
    url: `user/saveAddress`,
    method: "post",
    data,
  });
}
// 删除地址
export function delAddress(data) {
  return request({
    url: `user/saveAddress`,
    method: "post",
    data,
  });
}

// 账户信息
export function accountList(data) {
  return request({
    url: "user/orderList",
    method: "get",
    params: data,
  });
}

// 流动客户 ---- 升级为协议客户
export function customer(data) {
  return request({
    url: `user/upgrade`,
    method: "post",
    data,
  });
}
// 结算记录
export function settleList(data) {
  return request({
    url: `user/settleList`,
    method: "get",
    params: data,
  });
}
// 调整结算金额
export function addsettleList(data) {
  return request({
    url: `user/settle`,
    method: "post",
    data,
  });
}
// 识别身份证
export function shibieShen(data) {
  return request({
    url: `user/identify/id-card`,
    method: "post",
    data,
  });
}
export function shibieYing(data) {
  return request({
    url: `user/identify/company`,
    method: "post",
    data,
  });
}
// 修改用户密码
export function editUserpwd(data) {
  return request({
    url: `userBack/updatePassword`,
    method: "post",
    data,
  });
}
