<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "app",
  components: {},
  watch: {
    $route: {
      handler: function (to, from) {
        this.setActivePath(to.path);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["setActivePath"]),
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body,
html,
#app {
  width: 100%;
  height: 100%;
}
</style>
