import axios from "axios";
import { Loading, Message } from "element-ui";
import router from "../router";
import { tansParams, blobValidate } from "./ruoyi";
import { saveAs } from "file-saver";
let downloadLoadingInstance;
// loading加载
// 开始函数
let loading;
function start() {
  loading = Loading.service({
    lock: true,
    text: "拼命加载中...",
    background: "rbga(0,0,0,0.7)",
  });
}

// 结束函数
function end() {
  loading.close();
}
// 创建axios实例
const service = axios.create({
  baseURL: "https://api.zgjinding.cn/system-api/",
    // baseURL: "http://192.168.0.10:9100/system-api/",
  timeout: 30000,
});

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    if (localStorage.token) {
      config.headers.token = localStorage.token;
    }
    start();
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    end();
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
   (response)=> {
    if (response.data.code === 20107) {
      localStorage.clear();
      router.push('/login');
    }

    end();
    return response;
  },
   (error) => {
    console.log(error, 'error');
    end();
    return Promise.reject(error);
  }
);
// 通用下载方法
export function download(url, params, filename, config) {
  downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)" });
  return service
    .get(url, params, {
      transformRequest: [
        (params) => {
          return tansParams(params);
        },
      ],
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      responseType: "blob",
      ...config,
    })
    .then(async (data) => {
      const isBlob = blobValidate(data);
      if (isBlob) {
        const blob = new Blob([data]);
        saveAs(blob, filename);
      } else {
        const resText = await data.text();
        const rspObj = JSON.parse(resText);
        const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode["default"];
        Message.error(errMsg);
      }
      downloadLoadingInstance.close();
    })
    .catch((r) => {
      console.error(r);
      Message.error("下载文件出现错误，请联系管理员！");
      downloadLoadingInstance.close();
    });
}

export default service;
