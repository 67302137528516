import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/",
        name: "index",
        component: () => import("../views/Index.vue"),
      },
      {
        path: "index",
        name: "index",
        component: () => import("../views/Index.vue"),
      },
      {
        path: "user/agreement/profile",
        name: "users",
        component: () => import("../components/users/users.vue"),
      },
      {
        path: "user/client/agreements",
        name: "userClient",
        component: () => import("../components/users/userClient.vue"),
      },
      {
        path: "user/message",
        name: "roles",
        component: () => import("../components/userRole/roles.vue"),
      },
      {
        path: "user/client/messages",
        name: "rolesMessages",
        component: () => import("../components/userRole/roleMessage.vue"),
      },
      {
        path: "pa/message",
        name: "paMessage",
        component: () => import("../components/PA/paMessage.vue"),
      },
      {
        path: "pa/ProhibitedList",
        name: "ProhibitedList",
        component: () => import("../components/PA/ProhibitedList.vue"),
      },
      {
        path: "pa/agreement",
        name: "agreement",
        component: () => import("../components/PA/agreement.vue"),
      },
      {
        path: "Protocoltask",
        name: "Protocoltask",
        component: () => import("../components/Protocoltask/Protocoltask.vue"),
      },
      {
        path: "partnerke",
        name: "partnerke",
        component: () => import("../components/partner/partnerke.vue"),
      },
      {
        path: "partnerMessage",
        name: "partnerMessage",
        component: () => import("../components/partner/partnerMessage.vue"),
      },
      {
        path: "keList",
        name: "keList",
        component: () => import("../components/partner/keList.vue"),
      },
      {
        path: "keDing",
        name: "keDing",
        component: () => import("../components/partner/keDing.vue"),
      },
      {
        path: "renDing",
        name: "renDing",
        component: () => import("../components/partner/renDing.vue"),
      },
      {
        path: "partner",
        name: "partnerMa",
        component: () => import("../components/partner/partnerMa.vue"),
      },
      //协议订单统计
      {
        path: "contractStatistics",
        name: "contractStatistics",
        component: () => import("../components/caiwu/contractStatistics.vue"),
      },
      // 流动订单系统
      {
        path: "flowStatistics",
        name: "flowStatistics",
        component: () => import("../components/caiwu/flowStatistics.vue"),
      },
      // 收支统计
      {
        path: "expenditureStatistics",
        name: "expenditureStatistics",
        component: () => import("../components/caiwu/expenditureStatistics.vue"),
      },
      // 体现申请
      {
        path: "withdrawApplication",
        name: "withdrawApplication",
        component: () => import("../components/caiwu/withdrawApplication.vue"),
      },
      // 体现记录
      {
        path: "withdraw",
        name: "withdraw",
        component: () => import("../components/caiwu/withdraw.vue"),
      },
      {
        path: "depositRecord",
        name: "depositRecord",
        component: () => import("../components/caiwu/depositRecord.vue"),
      },
      // 开票申请
      {
        path: "billingApplication",
        name: "billingApplication",
        component: () => import("../components/caiwu/billingApplication.vue"),
      },
      // 开票记录
      {
        path: "billing",
        name: "billing",
        component: () => import("../components/caiwu/billing.vue"),
      },
      {
        path: "ProtocolList",
        name: "ProtocolList",
        component: () => import("../components/Protocoltask/ProtocolList.vue"),
      },
      {
        path: "Fluidtask",
        name: "Fluidtask",
        component: () => import("../components/Protocoltask/Fluidtask.vue"),
      },
      {
        path: "FluidList",
        name: "FluidList",
        component: () => import("../components/Protocoltask/FluidList.vue"),
      },
      {
        path: "Feedbacktype",
        name: "Feedbacktype",
        component: () => import("../components/kefu/Feedbacktype.vue"),
      },
      {
        path: "Feedback",
        name: "Feedback",
        component: () => import("../components/kefu/Feedback.vue"),
      },
      {
        path: "phone",
        name: "phone",
        component: () => import("../components/kefu/phone.vue"),
      },
      {
        path: "manager",
        name: "manager",
        component: () => import("../components/kefu/manager.vue"),
      },
      {
        path: "allocation",
        name: "allocation",
        component: () => import("../components/shezhi/allocation.vue"),
      },
      {
        path: "banner",
        name: "banner",
        component: () => import("../components/shezhi/banner.vue"),
      },
      {
        path: "announcement",
        name: "announcement",
        component: () => import("../components/shezhi/announcement.vue"),
      },
      {
        path: "bonusTag",
        name: "bonusTag",
        component: () => import("../components/shezhi/bonusTag.vue"),
      },
      {
        path: "cancel",
        name: "cancel",
        component: () => import("../components/shezhi/cancel.vue"),
      },
      {
        path: "Evaluationtag",
        name: "Evaluationtag",
        component: () => import("../components/shezhi/Evaluationtag.vue"),
      },
      {
        path: "taskConfig",
        name: "taskConfig",
        component: () => import("../components/shezhi/taskConfig.vue"),
      },
      {
        path: "InviteRewards",
        name: "InviteRewards",
        component: () => import("../components/shezhi/InviteRewards.vue"),
      },
      {
        path: "PAallocation",
        name: "PAallocation",
        component: () => import("../components/shezhi/PAallocation.vue"),
      },
      {
        path: "PAskilltag",
        name: "PAskilltag",
        component: () => import("../components/shezhi/PAskilltag.vue"),
      },
      {
        path: "PADeposit",
        name: "PADeposit",
        component: () => import("../components/shezhi/deposit.vue"),
      },
      {
        path: "PAstarConfiguration",
        name: "PAstarConfiguration",
        component: () => import("../components/shezhi/PAstarConfiguration.vue"),
      },
      {
        path: "Renewal",
        name: "Renewal",
        component: () => import("../components/shezhi/Renewal.vue"),
      },
      {
        path: "specialPA",
        name: "specialPA",
        component: () => import("../components/shezhi/specialPA.vue"),
      },
      {
        path: "specialPAallocation",
        name: "specialPAallocation",
        component: () => import("../components/shezhi/specialPAallocation.vue"),
      },
      {
        path: "content",
        name: "content",
        component: () => import("../components/shezhi/content.vue"),
      },
      {
        path: "account",
        name: "account",
        component: () => import("../components/roles/account.vue"),
      },
      {
        path: "roles",
        name: "roles",
        component: () => import("../components/roles/roles.vue"),
      },
    ],
  },
];
const originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
const router = new VueRouter({
  routes,
});
// 路由守卫
router.beforeEach((to, from, next) => {
  const isLogin = localStorage.token ? true : false;
  if (to.path == "/login") {
    next();
  } else {
    isLogin ? next() : next("/login");
  }
});
export default router;
