import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import moment from "moment";
import treeTable from "vue-table-with-tree-grid";
import { download } from "@/http/request";
// 富文本组件
import Editor from "@/components/Editor";
// 文件上传组件
import FileUpload from "@/components/FileUpload";
Vue.component("TreeTable", treeTable);
Vue.component.moment = moment;
// 时间格式
// Vue.filter("dataForm", function (date) {
//   return moment(date).format("YYYY-MM-DD, hh:mm:ss");
// });
Vue.component("Editor", Editor);
Vue.component("FileUpload", FileUpload);
Vue.config.productionTip = false;
Vue.prototype.download = download;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
