import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tokenInfo:{},
    activePath: '',
  },
  getters: {
  },
  mutations: {
    SET_TOKEN_INFO(state,value){
      state.tokenInfo=value
    },
    SET_ACTIVE_PATH(state, value){
      state.activePath = value
    }
  },
  actions: {
    setToken(context,value){
      context.commit("SET_TOKEN_INFO",value)
    },
    setActivePath(context, value){
      context.commit("SET_ACTIVE_PATH",value)
    }
  },
  modules: {
  }
})
