<!-- 登录 -->
<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 图片 -->
      <div class="login_img">
        <img
          src="../assets/logo.png"
          style="width: 100%; height: 100%; border-radius: 50%"
          alt=""
        />
      </div>
      <!-- 表格 -->
      <div class="login_form">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="username">
            <el-input
              v-model="ruleForm.username"
              autocomplete="off"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="ruleForm.password"
              autocomplete="off"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >登录</el-button
            >
            <!-- <el-button>重置</el-button> -->
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "../http/api";
export default {
  name: "Login",
  data() {
    return {
      ruleForm: {
        username: "admin",
        password: "123456",
      },
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    // 登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          login(this.ruleForm).then((res) => {
          // console.log(res);
            this.$message({
              type: "success",
              message: res.data.message,
            });
            const { token } = res.data.data;
            localStorage.setItem("token", token);
            localStorage.setItem("avatar", res.data.data.avatar);
            localStorage.setItem("isPartner", res.data.data.isPartner);
            // 解析token
            this.$store.dispatch("setToken", token);
            this.$router.push("/home/");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  watch: {},
  mixins: [],
};
</script>
<style  scoped lang="less">
.login_container {
  width: 100%;
  height: 100%;
  background-color: #e94d1a;
  // position: relative;
  .login_box {
    width: 500px;
    height: 320px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    .login_img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      // top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 150px;
      text-align: center;
      padding: 10px;
      box-shadow: 0 0 10px #eee;
    }
    .login_form {
      width: 500px;
      margin-top: 100px;
      padding: 0 20px;
      box-sizing: border-box;
      text-align: center;
    }
  }
}
</style>
